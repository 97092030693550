import classnames from 'classnames';
import { TEXT_VARIANTS } from 'constants/text';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import RocketIcon from 'style-book/icons/RocketIcon';
import TextComponent from 'style-book/text/TextComponent';
import PageSection, { SECTION_BACKGROUND_TYPES } from 'widgets/sections/PageSection';

import './SuccessSection.scss';

function SuccessSection() {
  return (
    <PageSection
      className="success-section"
      contentClassName="success-content"
      backgroundType={SECTION_BACKGROUND_TYPES.BOTTOM_CURLY_WAVE}>
      <div className="success-title">
        <TextComponent variant={TEXT_VARIANTS.SUBTITLE}>
          {"Imagine What's Possible with Clear Marketing Insights"}
        </TextComponent>
        <RocketIcon iconClassName="scale-up-animation" size="extra" />
      </div>
      <TextComponent variant={TEXT_VARIANTS.BODY}>
        {'With goalskeeper.io, you’ll finally have the insights you need to:'}
      </TextComponent>
      <div className="success">
        <div className="success-bullets">
          <div className="success-bullet">
            <RocketIcon size="medium" />
            <TextComponent variant={TEXT_VARIANTS.BODY}>
              {"Increase traffic & leads - Focus on what's actually working"}
            </TextComponent>
          </div>
          <div className="success-bullet">
            <RocketIcon size="medium" />
            <TextComponent variant={TEXT_VARIANTS.BODY}>
              {'Save hours every week - No more wasting time on manual reports.'}
            </TextComponent>
          </div>
          <div className="success-bullet">
            <RocketIcon size="medium" />
            <TextComponent variant={TEXT_VARIANTS.BODY}>
              {'Grow your customer base - Make data-driven marketing decisions with confidence.'}
            </TextComponent>
          </div>
        </div>
        <StaticImage
          className={classnames('success-image', 'scale-up-animation')}
          src={'../../../images/success.webp'}
          placeholder="blurred"
          loading="lazy"
          alt="Happy business owner after getting actionable marketing insights using goalskeeper.io"
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
    </PageSection>
  );
}

export default SuccessSection;
