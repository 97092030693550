import classnames from 'classnames';
import { TEXT_VARIANTS } from 'constants/text';
import React from 'react';
import QuestionMarkIcon from 'style-book/icons/QuestionMarkIcon';
import WastingMoneyIcon from 'style-book/icons/WastingMoneyIcon';
import WastingTimeIcon from 'style-book/icons/WastingTimeIcon';
import TextComponent from 'style-book/text/TextComponent';
import BallWidget from 'widgets/ball/BallWidget';
import PageSection, { SECTION_BACKGROUND_TYPES } from 'widgets/sections/PageSection';

import './ProblemSection.scss';

function ProblemSection() {
  return (
    <PageSection
      className="problem-section"
      contentClassName="problem-content"
      backgroundType={SECTION_BACKGROUND_TYPES.TOP_CURLY_WAVE}>
      <TextComponent className="problem-title" variant={TEXT_VARIANTS.SUBTITLE}>
        Your Biggest Marketing Enemy: Wasted Time & Money
      </TextComponent>

      <div className="problem-item">
        <div className="problem-description">
          <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
            Too many tools, no clear answers
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.BODY}>
            Jumping between Google Analytics, Facebook Ads, email reports, and spreadsheets.
          </TextComponent>
        </div>
        <QuestionMarkIcon iconClassName="problem-item-icon" />
      </div>
      <div className="problem-item">
        <WastingTimeIcon iconClassName="problem-item-icon" />
        <div className="problem-description">
          <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
            Wasting hours trying to track results
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.BODY}>
            Spending more time analyzing than growing your business.
          </TextComponent>
        </div>
      </div>
      <div className="problem-item">
        <div className="problem-description">
          <TextComponent variant={TEXT_VARIANTS.SECTION_HEADER}>
            Spending money on ads and social media
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.BODY}>
            {"But still wondering if they're actually working"}.
          </TextComponent>
        </div>
        <WastingMoneyIcon iconClassName="problem-item-icon" />
      </div>
      <BallWidget className={classnames('ball', 'scale-up-animation')} size="big" />
    </PageSection>
  );
}

export default ProblemSection;
