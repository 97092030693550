import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './VerticalDividerComponent.scss';

function VerticalDividerComponent({ className }) {
  return <div className={classnames('vertical-divider-component', className)} />;
}

VerticalDividerComponent.propTypes = {
  className: PropTypes.string
};

export default VerticalDividerComponent;
