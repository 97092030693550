import { TEXT_VARIANTS } from 'constants/text';
import PropTypes from 'prop-types';
import React from 'react';
import DividerComponent from 'style-book/divider/DividerComponent';
import TextComponent from 'style-book/text/TextComponent';
import PageSection from 'widgets/sections/PageSection';

import './FaqSection.scss';
import FaqWidgetItem from './FaqWidgetItem';

function FaqSection({ faq }) {
  return (
    <PageSection className="faq-section" contentClassName="faq-content">
      <TextComponent className="faq-title" variant={TEXT_VARIANTS.SUBTITLE}>
        Frequently Asked Questions
      </TextComponent>
      <div className="faq-list">
        {faq.map((row, index) => (
          <React.Fragment key={row.question}>
            <FaqWidgetItem question={row.question} answer={row.answer} />
            {index < faq.length - 1 && <DividerComponent />}
          </React.Fragment>
        ))}
      </div>
    </PageSection>
  );
}

FaqSection.propTypes = {
  faq: PropTypes.array
};

export default FaqSection;
