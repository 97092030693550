import { TEXT_VARIANTS } from 'constants/text';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonComponent from 'style-book/button/ButtonComponent';
import TextComponent from 'style-book/text/TextComponent';

import './BlogPostItemWidget.scss';

function BlogPostItemWidget({ title, description, url }) {
  return (
    <div className="blog-post-item-widget">
      <TextComponent variant={TEXT_VARIANTS.HEADING}>{title}</TextComponent>
      <TextComponent className="description" variant={TEXT_VARIANTS.PARAGRAPH_MEDIUM}>
        {description}
      </TextComponent>
      <ButtonComponent className="read-more" variant="outline" goto={url} target="_blank">
        Read More
      </ButtonComponent>
    </div>
  );
}

BlogPostItemWidget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default BlogPostItemWidget;
