import { TEXT_VARIANTS } from 'constants/text';
import React from 'react';
import BaseLandingPageService from 'services/BaseLandingPageService';
import DividerComponent from 'style-book/divider/DividerComponent';
import VerticalDividerComponent from 'style-book/divider/VerticalDividerComponent';
import MagnifyingGlassIcon from 'style-book/icons/MagnifyingGlassIcon';
import TextComponent from 'style-book/text/TextComponent';
import PageSection from 'widgets/sections/PageSection';
import BlogPostItemWidget from 'widgets/sections/blogs/BlogPostItemWidget';

import './BlogSection.scss';

function BlogSection() {
  const lpService = new BaseLandingPageService();
  const blogPosts = lpService.getBlogPosts();
  return (
    <PageSection className="blog-section" contentClassName="blog-content">
      <div className="blog-title">
        <TextComponent variant={TEXT_VARIANTS.SUBTITLE}>
          Want to learn more about marketing insights?
        </TextComponent>
        <MagnifyingGlassIcon size="extra" />
      </div>
      <div className="blog-posts">
        {blogPosts &&
          blogPosts.map((post, index) => (
            <React.Fragment key={post.url}>
              <BlogPostItemWidget
                title={post.title}
                description={post.description}
                url={post.url}
              />
              {index < blogPosts.length - 1 && <DividerComponent className="posts-divider" />}
            </React.Fragment>
          ))}
      </div>
    </PageSection>
  );
}

export default BlogSection;
