import classnames from 'classnames';
import { TEXT_VARIANTS } from 'constants/text';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import TextComponent from 'style-book/text/TextComponent';
import PageSection from 'widgets/sections/PageSection';

import './StepsSection.scss';

function StepsSection() {
  return (
    <PageSection className="steps-section" contentClassName="steps-content">
      <TextComponent variant={TEXT_VARIANTS.SUBTITLE}>How It Works in 4 Simple Steps</TextComponent>
      <div className="step-container">
        <div className="step">
          <TextComponent variant={TEXT_VARIANTS.HEADING}>1</TextComponent>
          <TextComponent className="step-description" variant={TEXT_VARIANTS.SUBHEADING}>
            Easily integrate Google Analytics, Mailchimp, Facebook Ads, and more.
          </TextComponent>
        </div>
        <StaticImage
          className="logos-image"
          src={'../../../images/logos_4x.webp'}
          placeholder="blurred"
          alt="A sample of logos of marketing tools that goalskeeper.io provides data integration with them"
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
      <div className="step-container">
        <StaticImage
          className="question-mark-image"
          src={'../../../images/question_mark.webp'}
          placeholder="blurred"
          alt="An illustration of marketing charts that goalskeeper.io can help with building without being data analysis"
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
        <div className={classnames('step', 'align-end')}>
          <TextComponent variant={TEXT_VARIANTS.HEADING}>2</TextComponent>
          <TextComponent className="step-description" variant={TEXT_VARIANTS.SUBHEADING}>
            Turn your marketing questions into actionable charts with your data
          </TextComponent>
        </div>
      </div>
      <div className="step-container">
        <div className="step">
          <TextComponent variant={TEXT_VARIANTS.HEADING}>3</TextComponent>
          <TextComponent className="step-description" variant={TEXT_VARIANTS.SUBHEADING}>
            Build dashboard with the data that you care, we will add insights
          </TextComponent>
        </div>
        <StaticImage
          className="dashboard-builder-image"
          src={'../../../images/dashboard-builder.png'}
          placeholder="blurred"
          alt="An illustration of building a marketing dashboard quickly, like goalkeeper.io, helps to build without data analysis."
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
      <div className="step-container">
        <div className="container">
          <StaticImage
            className="marketing-goals-image"
            src={'../../../images/marketing_goals.webp'}
            placeholder="blurred"
            alt="An illustration of marketing goals that goalskeeper.io helps to set and achieve."
            transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
          />
        </div>
        <div className={classnames('step', 'align-end')}>
          <TextComponent variant={TEXT_VARIANTS.HEADING}>4</TextComponent>
          <TextComponent className="step-description" variant={TEXT_VARIANTS.SUBHEADING}>
            Easily add goals and we help you to achieve them
          </TextComponent>
        </div>
      </div>
      <StaticImage
        className="path-to-success-image"
        src={'../../../images/path_to_success.webp'}
        placeholder="blurred"
        alt="A simple visualization of 4 steps to marketing success with goalskeeper.io"
        transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
      />
    </PageSection>
  );
}

export default StepsSection;
