import classnames from 'classnames';
import { TEXT_VARIANTS } from 'constants/text';
import React from 'react';
import QuotesIcon from 'style-book/icons/QuotesIcon';
import TextComponent from 'style-book/text/TextComponent';
import PageSection, { SECTION_BACKGROUND_TYPES } from 'widgets/sections/PageSection';

import './ProofSection.scss';

function ProofSection() {
  return (
    <PageSection
      className="proof-section"
      contentClassName="proof-content"
      backgroundType={SECTION_BACKGROUND_TYPES.BOTTOM_BIG_WAVE}>
      <TextComponent className="proof-subtitle" variant={TEXT_VARIANTS.HEADING}>
        {"We Get It. Running Your Own Marketing Shouldn't Be This Hard"}
      </TextComponent>
      <TextComponent className="proof-description" variant={TEXT_VARIANTS.BODY}>
        {
          "You're juggling ads, emails, and social media. You don't have time for complex analytics. You just want to know what's working and what's not."
        }
      </TextComponent>
      <TextComponent className="proof-title" variant={TEXT_VARIANTS.SUBTITLE}>
        {
          "That's why we built goalskeeper.io—trusted by small business owners to simplify marketing analytics."
        }
      </TextComponent>
      <div className="quotes">
        <div className="quote-container">
          <QuotesIcon />
          <TextComponent variant={TEXT_VARIANTS.BODY}>
            Focus your budget on what drives results, without the guesswork.
          </TextComponent>
        </div>
        <div className={classnames('quote-container', 'main-quote')}>
          <QuotesIcon />
          <TextComponent variant={TEXT_VARIANTS.BODY}>
            Stay in control of your marketing—no agencies or experts needed.
          </TextComponent>
        </div>
        <div className="quote-container">
          <QuotesIcon />
          <TextComponent variant={TEXT_VARIANTS.BODY}>
            Understand what’s working and where to improve—at a glance.
          </TextComponent>
        </div>
      </div>
    </PageSection>
  );
}

export default ProofSection;
