import React from 'react';
import SvgIcon from 'style-book/icons/svg-icon/SvgIcon';

function DoNotEnterIcon(props) {
  return (
    <SvgIcon viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M50 0C22.4056 0 0 22.3887 0 49.9891C0 77.6113 22.4056 100 50 100C77.5944 100 100 77.6113 100 49.9891C100 22.3887 77.5944 0 50 0ZM50 90.0109C27.8984 90.0109 10.0087 72.0955 10.0087 49.9891C10.0087 40.7383 13.1568 32.2476 18.4542 25.494L74.5115 81.5635C67.7594 86.8621 59.2488 90.0109 50 90.0109ZM81.5458 74.506L25.5102 18.4365C32.2623 13.1379 40.7512 9.98914 50 9.98914C72.1016 9.98914 89.9913 27.9044 89.9913 49.9891C89.9913 59.24 86.8433 67.7524 81.5458 74.506Z"
        fill="#FF3025"
      />
    </SvgIcon>
  );
}

export default DoNotEnterIcon;
