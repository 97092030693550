import React from 'react';
import SvgIcon from 'style-book/icons/svg-icon/SvgIcon';

function WastingMoneyIcon(props) {
  return (
    <SvgIcon viewBox="0 0 116 116" fill="none" {...props}>
      <path
        d="M71.0319 49.3945H16.2246C15.334 49.3945 14.6152 50.1185 14.6152 51.0143V59.1341C14.6152 60.0299 15.334 60.7539 16.2246 60.7539H18.6725L22.4538 104.572C22.5267 105.405 23.2246 106.051 24.0632 106.051H63.1986C64.0319 106.051 64.7298 105.405 64.8027 104.572L68.584 60.7539H71.0319C71.9225 60.7539 72.6465 60.0299 72.6465 59.1341V51.0143C72.6465 50.1185 71.9225 49.3945 71.0319 49.3945ZM61.7194 102.811H53.3027L55.709 69.3945C55.7767 68.4987 55.1048 67.7227 54.2194 67.6602C53.3496 67.5924 52.5579 68.2643 52.4954 69.1602L50.0736 102.811H37.1882L34.7611 69.1602C34.6986 68.2643 33.9225 67.5924 33.0371 67.6602C32.1517 67.7227 31.485 68.4987 31.5475 69.3945L33.9538 102.811H25.5423L21.9121 60.7539H65.3496L61.7194 102.811ZM69.4225 57.5143C67.1569 57.5143 20.1048 57.5143 17.8392 57.5143V52.6341H69.4225V57.5143Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M56.9581 37.997C52.0467 35.7939 46.4008 37.6897 44.3696 42.2158C43.7654 43.4658 43.13 45.7574 45.0623 46.1324C46.8331 46.3616 46.8383 44.747 47.3071 43.5543C49.5571 38.4658 57.8488 39.7835 59.4113 45.0439C59.6873 45.9033 60.6248 46.3512 61.4477 46.0751C64.8852 44.4189 59.229 38.7158 56.9581 37.997Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M66.5885 37.8209C67.3854 38.1855 68.3541 37.8314 68.7239 37.0084L71.6979 30.3678C72.1614 29.3886 71.427 28.1491 70.3489 28.0918C70.0052 28.0605 66.9687 27.6803 66.3958 23.8209C66.3176 23.2741 65.9687 22.8105 65.4687 22.5814L62.8229 21.3834C62.3177 21.1595 61.7395 21.2011 61.2812 21.5032C58.0208 23.6386 55.7239 21.6022 55.4843 21.3782C54.7343 20.5918 53.302 20.8522 52.8801 21.8626L49.9062 28.5084C49.0468 30.4355 51.9739 31.7636 52.8437 29.8366L55.0416 24.9303C56.8177 25.7897 59.4062 26.2064 62.2604 24.6803L63.3801 25.1907C64.1301 28.3418 66.1614 30.0136 67.9791 30.7793L65.7812 35.6803C65.4166 36.4928 65.776 37.4564 66.5885 37.8209Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M33.0785 32.179H24.8701C22.7607 32.1842 22.7607 35.4082 24.8701 35.4134H29.1201V37.3301C29.1201 38.2259 29.8441 38.9499 30.7347 38.9499C31.6253 38.9499 32.3441 38.2259 32.3441 37.3301V35.4134C39.5941 35.9655 40.3337 25.4759 33.0785 25.1165H28.391C26.7347 25.1634 25.8389 22.9863 27.042 21.8509C27.4014 21.4863 27.8805 21.2884 28.391 21.2884H36.5941C38.6982 21.2832 38.7035 18.0592 36.5941 18.054H32.3441V16.1374C32.3441 15.2415 31.6253 14.5176 30.7347 14.5176C29.8441 14.5176 29.1201 15.2415 29.1201 16.1374V18.054C21.8753 17.4967 21.1305 27.9915 28.391 28.3509H33.0785C35.5837 28.3926 35.5785 32.1322 33.0785 32.179Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M30.7344 44.5401C32.8594 44.5401 34.9167 44.1547 36.8594 43.4359C35.526 45.4828 38.4219 47.3734 39.6042 45.2016L53.4479 14.3057L76.9635 24.9412L68.4844 43.8787C67.625 45.8005 70.5521 47.1391 71.4219 45.2016L80.5625 24.7901C80.9271 23.9776 80.5677 23.0193 79.7552 22.6495L53.3021 10.6912C52.5208 10.3214 51.5104 10.7016 51.1667 11.5037L47.2604 20.2276C40.0104 2.1599 13.3021 7.0349 13 26.7328C13 36.5505 20.9531 44.5401 30.7344 44.5401ZM30.7344 12.1651C38.1458 12.1651 44.2552 17.6495 45.125 24.9828L38.9948 38.6703C29.7396 45.4828 16 38.2537 16.224 26.7328C16.224 18.7016 22.7344 12.1651 30.7344 12.1651Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M103.631 90.0765L97.2243 74.7067L101.683 72.8317C102.49 72.514 102.896 71.5087 102.547 70.7119L96.6879 56.6598C96.3441 55.8369 95.4014 55.4515 94.5785 55.79L90.1202 57.665L83.7139 42.2952C83.365 41.4723 82.4222 41.0869 81.6045 41.4254L74.167 44.5504C73.3597 44.8733 72.9535 45.8733 73.3025 46.6702L93.2191 94.4515C93.4795 95.0712 94.0785 95.4462 94.7087 95.4462C94.7035 95.8212 102.563 92.1546 102.766 92.1962C103.568 91.8733 103.98 90.8733 103.631 90.0765ZM91.365 60.6546L94.3389 59.4046L98.9535 70.4723L95.9795 71.7171C94.9066 69.1442 92.4066 63.1598 91.365 60.6546ZM95.5733 91.7067L76.8962 46.915L81.3597 45.04C86.8545 58.2223 94.5525 76.691 100.032 89.8317L95.5733 91.7067Z"
        fill="var(--primary-text-color)"
      />
    </SvgIcon>
  );
}

export default WastingMoneyIcon;
