import classnames from 'classnames';
import { TEXT_VARIANTS } from 'constants/text';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import TextComponent from 'style-book/text/TextComponent';
import BallWidget from 'widgets/ball/BallWidget';
import PageSection, { SECTION_BACKGROUND_TYPES } from 'widgets/sections/PageSection';

import './HookSection.scss';

function HookSection() {
  return (
    <PageSection
      className="hook-section"
      contentClassName="hook-content"
      backgroundType={SECTION_BACKGROUND_TYPES.BOTTOM_CURLY_WAVE}>
      <TextComponent className="hook-title" variant={TEXT_VARIANTS.SUBTITLE}>
        {"What's Holding You Back from DIY Marketing Success?"}
      </TextComponent>
      <BallWidget className={classnames('ball', 'scale-up-animation')} size="big" />
      <div className="hook-container">
        <TextComponent className="hook-description" variant={TEXT_VARIANTS.BODY}>
          {`You're handling marketing yourself, but results just aren't coming. Why? Your marketing
          data is scattered, your efforts feel random, and you're stuck guessing what actually
          works.`}
        </TextComponent>
        <StaticImage
          className={classnames('frustration-image', 'scale-up-animation')}
          src={'../../../images/frustration.webp'}
          placeholder="blurred"
          loading="lazy"
          alt="A business owner feeling overwhelmed with DIY marketing could get back on track by getting actionable marketing insights using goalskeeper.io"
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
    </PageSection>
  );
}

export default HookSection;
