import React from 'react';
import SvgIcon from 'style-book/icons/svg-icon/SvgIcon';

function QuotesIcon(props) {
  return (
    <SvgIcon viewBox="0 0 111 80" fill="none" {...props}>
      <path
        d="M50.7132 25.1935C50.7132 54.8029 28.8174 75.3081 0.333008 79.3758C16.0153 71.1727 25.9059 60.1935 27.307 50.3237C13.859 51.4123 1.68717 40.9123 0.442383 27.5685C-0.791992 14.3237 8.86947 2.05289 22.0726 0.245602C26.2601 -0.327315 30.6143 0.151852 34.5518 1.68831C36.557 2.45914 38.432 3.46956 40.1768 4.71956C41.6976 5.81852 43.7497 7.27164 44.7288 8.90706C48.6038 13.3862 50.7132 19.2873 50.7132 25.1935Z"
        fill="var(--primary-shade4-color)"
      />
      <path
        d="M110.896 25.1935C110.896 54.8029 89.0052 75.3081 60.5156 79.3758C76.1979 71.1727 86.0885 60.1935 87.4844 50.3237C74.0417 51.4123 61.8698 40.9123 60.625 27.5685C59.3958 14.3237 69.0521 2.05289 82.2552 0.245602C86.4427 -0.327315 90.7969 0.151852 94.7396 1.68831C96.7396 2.45914 98.6146 3.46956 100.359 4.71956C101.88 5.81852 103.932 7.27164 104.911 8.90706C108.786 13.3862 110.896 19.2873 110.896 25.1935Z"
        fill="var(--primary-shade4-color)"
      />
    </SvgIcon>
  );
}

export default QuotesIcon;
