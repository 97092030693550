import { HOME_PAGE_FAQ } from 'constants/faq';
import { MAIN_SCHEMA } from 'constants/schema';
import React from 'react';
import HomePageComponent from 'style-book/home-page/HomePageComponent';
import SeoComponent from 'style-book/seo/SeoComponent';
import MainSection from 'widgets/sections/above-the-fold/MainSection';
import BlogSection from 'widgets/sections/blogs/BlogSection';
import CtaSection from 'widgets/sections/cta/CtaSection';
import FaqSection from 'widgets/sections/faq/FaqSection';
import FauilreSection from 'widgets/sections/fauilre/FauilreSection';
import GuideSection from 'widgets/sections/guide/GuideSection';
import HookSection from 'widgets/sections/hook/HookSection';
import ProblemSection from 'widgets/sections/problem/ProblemSection';
import ProofSection from 'widgets/sections/proof/ProofSection';
import StepsSection from 'widgets/sections/steps/StepsSection';
import SuccessSection from 'widgets/sections/success/SuccessSection';
import VideoSection from 'widgets/sections/video/VideoSection';

import './index.css';

function IndexPage() {
  return (
    <HomePageComponent>
      <MainSection />
      <VideoSection />
      <HookSection />
      <ProblemSection />
      <GuideSection />
      <ProofSection />
      <StepsSection />
      <FauilreSection />
      <SuccessSection />
      <CtaSection />
      <FaqSection faq={HOME_PAGE_FAQ} />
      <BlogSection />
    </HomePageComponent>
  );
}

export default IndexPage;

export function Head() {
  return (
    <SeoComponent
      title="Simplify Your Small Business Marketing Success with goalsKeeper.io - Effortless Data Insights for Digital Marketers"
      description="Simplify marketing success for your small business with GoalsKeeper.io. Our platform provides digital marketers with intuitive data insights, eliminating the complexity of data analysis. Effortlessly connect your marketing tools, create customized dashboards, and make informed decisions to optimize your strategies. Drive results and achieve your marketing goals with ease. Sign up for a free trial today!"
      canonicalUrl="goalskeeper.io/"
      schema={MAIN_SCHEMA}
    />
  );
}
