import classnames from 'classnames';
import React, { useMemo } from 'react';

function BallWidget({ className, size }) {
  const [width, heigth] = useMemo(() => {
    if (size === 'big') return [244, 255];
    return [144, 155];
  }, [size]);
  return (
    <div className={classnames('ball-widget', className)}>
      <svg width={width} height={heigth} viewBox="0 0 244 255" fill="none">
        <g filter="url(#filter0_d_7424_21570)">
          <path
            d="M209.526 145.054C203.191 165.797 190.625 183.982 173.834 196.705C157.043 209.428 136.997 215.955 116.898 215.242C96.7994 214.529 77.8071 206.618 62.9556 192.773C48.104 178.928 38.2501 159.947 34.9679 138.863L111.219 121.762C111.688 124.775 113.096 127.487 115.218 129.465C117.34 131.443 120.053 132.573 122.925 132.675C125.796 132.777 128.66 131.844 131.059 130.026C133.458 128.209 135.254 125.61 136.159 122.647L209.526 145.054Z"
            fill="#D9D9D9"
          />
        </g>
        <circle cx="121.668" cy="119.82" r="97" fill="url(#paint0_linear_7424_21570)" />
        <defs>
          <filter
            id="filter0_d_7424_21570"
            x="0.967773"
            y="92.7617"
            width="242.559"
            height="161.533"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="9"
              operator="dilate"
              in="SourceAlpha"
              result="effect1_dropShadow_7424_21570"
            />
            <feOffset dy="5" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7424_21570"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7424_21570"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_7424_21570"
            x1="24.668"
            y1="119.821"
            x2="218.671"
            y2="119.821"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#0E98BA" />
            <stop offset="0.0078125" stopColor="#0E98BA" />
            <stop offset="0.015625" stopColor="#0F98BA" />
            <stop offset="0.0234375" stopColor="#1097BA" />
            <stop offset="0.03125" stopColor="#1097B9" />
            <stop offset="0.0390625" stopColor="#1197B9" />
            <stop offset="0.046875" stopColor="#1197B9" />
            <stop offset="0.0546875" stopColor="#1297B9" />
            <stop offset="0.0625" stopColor="#1396B9" />
            <stop offset="0.0703125" stopColor="#1396B9" />
            <stop offset="0.078125" stopColor="#1496B9" />
            <stop offset="0.0859375" stopColor="#1596B9" />
            <stop offset="0.09375" stopColor="#1596B9" />
            <stop offset="0.101562" stopColor="#1695B8" />
            <stop offset="0.109375" stopColor="#1695B8" />
            <stop offset="0.117188" stopColor="#1795B8" />
            <stop offset="0.125" stopColor="#1895B8" />
            <stop offset="0.132812" stopColor="#1894B8" />
            <stop offset="0.140625" stopColor="#1994B8" />
            <stop offset="0.148438" stopColor="#1A94B8" />
            <stop offset="0.15625" stopColor="#1A94B8" />
            <stop offset="0.164062" stopColor="#1B94B8" />
            <stop offset="0.171875" stopColor="#1B93B7" />
            <stop offset="0.179688" stopColor="#1C93B7" />
            <stop offset="0.1875" stopColor="#1D93B7" />
            <stop offset="0.195312" stopColor="#1D93B7" />
            <stop offset="0.203125" stopColor="#1E93B7" />
            <stop offset="0.210938" stopColor="#1F92B7" />
            <stop offset="0.21875" stopColor="#1F92B7" />
            <stop offset="0.226562" stopColor="#2092B7" />
            <stop offset="0.234375" stopColor="#2092B6" />
            <stop offset="0.242188" stopColor="#2192B6" />
            <stop offset="0.25" stopColor="#2291B6" />
            <stop offset="0.257812" stopColor="#2291B6" />
            <stop offset="0.265625" stopColor="#2391B6" />
            <stop offset="0.273438" stopColor="#2491B6" />
            <stop offset="0.28125" stopColor="#2490B6" />
            <stop offset="0.289062" stopColor="#2590B6" />
            <stop offset="0.296875" stopColor="#2590B6" />
            <stop offset="0.304688" stopColor="#2690B5" />
            <stop offset="0.3125" stopColor="#2790B5" />
            <stop offset="0.320312" stopColor="#278FB5" />
            <stop offset="0.328125" stopColor="#288FB5" />
            <stop offset="0.335938" stopColor="#298FB5" />
            <stop offset="0.34375" stopColor="#298FB5" />
            <stop offset="0.351562" stopColor="#2A8FB5" />
            <stop offset="0.359375" stopColor="#2A8EB5" />
            <stop offset="0.367188" stopColor="#2B8EB5" />
            <stop offset="0.375" stopColor="#2C8EB4" />
            <stop offset="0.382812" stopColor="#2C8EB4" />
            <stop offset="0.390625" stopColor="#2D8EB4" />
            <stop offset="0.398438" stopColor="#2E8DB4" />
            <stop offset="0.40625" stopColor="#2E8DB4" />
            <stop offset="0.414062" stopColor="#2F8DB4" />
            <stop offset="0.421875" stopColor="#2F8DB4" />
            <stop offset="0.429688" stopColor="#308CB4" />
            <stop offset="0.4375" stopColor="#318CB3" />
            <stop offset="0.445312" stopColor="#318CB3" />
            <stop offset="0.453125" stopColor="#328CB3" />
            <stop offset="0.460938" stopColor="#338CB3" />
            <stop offset="0.46875" stopColor="#338BB3" />
            <stop offset="0.476562" stopColor="#348BB3" />
            <stop offset="0.484375" stopColor="#348BB3" />
            <stop offset="0.492188" stopColor="#358BB3" />
            <stop offset="0.5" stopColor="#368BB3" />
            <stop offset="0.507812" stopColor="#368AB2" />
            <stop offset="0.515625" stopColor="#378AB2" />
            <stop offset="0.523438" stopColor="#388AB2" />
            <stop offset="0.53125" stopColor="#388AB2" />
            <stop offset="0.539062" stopColor="#398AB2" />
            <stop offset="0.546875" stopColor="#3989B2" />
            <stop offset="0.554688" stopColor="#3A89B2" />
            <stop offset="0.5625" stopColor="#3B89B2" />
            <stop offset="0.570312" stopColor="#3B89B2" />
            <stop offset="0.578125" stopColor="#3C88B1" />
            <stop offset="0.585938" stopColor="#3D88B1" />
            <stop offset="0.59375" stopColor="#3D88B1" />
            <stop offset="0.601562" stopColor="#3E88B1" />
            <stop offset="0.609375" stopColor="#3F88B1" />
            <stop offset="0.617188" stopColor="#3F87B1" />
            <stop offset="0.625" stopColor="#4087B1" />
            <stop offset="0.632812" stopColor="#4087B1" />
            <stop offset="0.640625" stopColor="#4187B0" />
            <stop offset="0.648438" stopColor="#4287B0" />
            <stop offset="0.65625" stopColor="#4286B0" />
            <stop offset="0.664062" stopColor="#4386B0" />
            <stop offset="0.671875" stopColor="#4486B0" />
            <stop offset="0.679688" stopColor="#4486B0" />
            <stop offset="0.6875" stopColor="#4585B0" />
            <stop offset="0.695312" stopColor="#4585B0" />
            <stop offset="0.703125" stopColor="#4685B0" />
            <stop offset="0.710938" stopColor="#4785AF" />
            <stop offset="0.71875" stopColor="#4785AF" />
            <stop offset="0.726562" stopColor="#4884AF" />
            <stop offset="0.734375" stopColor="#4984AF" />
            <stop offset="0.742188" stopColor="#4984AF" />
            <stop offset="0.75" stopColor="#4A84AF" />
            <stop offset="0.757812" stopColor="#4A84AF" />
            <stop offset="0.765625" stopColor="#4B83AF" />
            <stop offset="0.773438" stopColor="#4C83AF" />
            <stop offset="0.78125" stopColor="#4C83AE" />
            <stop offset="0.789062" stopColor="#4D83AE" />
            <stop offset="0.796875" stopColor="#4E83AE" />
            <stop offset="0.804688" stopColor="#4E82AE" />
            <stop offset="0.8125" stopColor="#4F82AE" />
            <stop offset="0.820312" stopColor="#4F82AE" />
            <stop offset="0.828125" stopColor="#5082AE" />
            <stop offset="0.835938" stopColor="#5181AE" />
            <stop offset="0.84375" stopColor="#5181AD" />
            <stop offset="0.851562" stopColor="#5281AD" />
            <stop offset="0.859375" stopColor="#5381AD" />
            <stop offset="0.867188" stopColor="#5381AD" />
            <stop offset="0.875" stopColor="#5480AD" />
            <stop offset="0.882812" stopColor="#5480AD" />
            <stop offset="0.890625" stopColor="#5580AD" />
            <stop offset="0.898438" stopColor="#5680AD" />
            <stop offset="0.90625" stopColor="#5680AD" />
            <stop offset="0.914062" stopColor="#577FAC" />
            <stop offset="0.921875" stopColor="#587FAC" />
            <stop offset="0.929688" stopColor="#587FAC" />
            <stop offset="0.9375" stopColor="#597FAC" />
            <stop offset="0.945312" stopColor="#597FAC" />
            <stop offset="0.953125" stopColor="#5A7EAC" />
            <stop offset="0.960938" stopColor="#5B7EAC" />
            <stop offset="0.96875" stopColor="#5B7EAC" />
            <stop offset="0.976562" stopColor="#5C7EAC" />
            <stop offset="0.984375" stopColor="#5D7DAB" />
            <stop offset="0.992188" stopColor="#5D7DAB" />
            <stop offset="1" stopColor="#5E7DAB" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default BallWidget;
