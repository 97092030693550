import classnames from 'classnames';
import { TEXT_VARIANTS } from 'constants/text';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import ButtonComponent from 'style-book/button/ButtonComponent';
import TextComponent from 'style-book/text/TextComponent';
import BallWidget from 'widgets/ball/BallWidget';
import PageSection from 'widgets/sections/PageSection';

import './MainSection.scss';

function MainSection() {
  return (
    <PageSection className="main-section">
      <div className="hero-container">
        <div className="hero-content">
          <TextComponent className="main-title" variant={TEXT_VARIANTS.TITLE}>
            Get More Customers with DIY Marketing Insights.
          </TextComponent>
          <span className="main-dsecription">
            stop guessing and start growing. Connect your tools, see what works, and save time—no
            tech skills required.
          </span>
          <ButtonComponent
            className="cta-button"
            variant="primary"
            goto="https://app.goalskeeper.io/signup">
            Start Your Free Trial Today
          </ButtonComponent>
        </div>
        <div className="hero-image">
          <BallWidget className={classnames('upper-ball', 'scale-up-animation')} size="big" />
          <StaticImage
            className={classnames('dashboard-image', 'scale-up-animation')}
            src={'../../../images/dashboard.webp'}
            placeholder="blurred"
            loading="lazy"
            alt="Sample of marketing data insights using goalskeeper.io"
            transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
          />
          <BallWidget className={classnames('bottom-ball', 'scale-up-animation')} size="small" />
        </div>
      </div>
    </PageSection>
  );
}

export default MainSection;
