import { TEXT_VARIANTS } from 'constants/text';
import React from 'react';
import ButtonComponent from 'style-book/button/ButtonComponent';
import TextComponent from 'style-book/text/TextComponent';
import PageSection, { SECTION_BACKGROUND_TYPES } from 'widgets/sections/PageSection';

import './CtaSection.scss';

function CtaSection() {
  return (
    <PageSection
      className="cta-section"
      contentClassName="cta-content"
      backgroundType={SECTION_BACKGROUND_TYPES.TOP_CURLY_WAVE}>
      <TextComponent className="cta-title" variant={TEXT_VARIANTS.SUBTITLE}>
        {"Ready to Grow Your Business? Let's Make It Happen!"}
      </TextComponent>
      <TextComponent className="cta-description" variant={TEXT_VARIANTS.BODY}>
        {
          'Get started with Goalskeeper.io today and stop wasting time on guesswork. Track what works, grow faster, and make data-driven decisions with ease.'
        }
      </TextComponent>
      <ButtonComponent
        className="cta-button"
        variant="primary"
        goto="https://app.goalskeeper.io/signup">
        Start Your Free Trial Today
      </ButtonComponent>
    </PageSection>
  );
}

export default CtaSection;
