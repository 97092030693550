import React from 'react';
import SvgIcon from 'style-book/icons/svg-icon/SvgIcon';

function WastingTimeIcon(props) {
  return (
    <SvgIcon viewBox="0 0 116 116" fill="none" {...props}>
      <path
        d="M35.042 12.8061C34.1514 13.2904 33.9066 14.4259 34.391 15.2384C34.8805 16.129 36.016 16.3686 36.9066 15.8842C37.7972 15.3998 38.042 14.2644 37.5524 13.4519C37.068 12.6446 35.9326 12.3165 35.042 12.8061Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M30.5838 19.0463C29.6932 18.5567 28.6411 18.8848 28.0734 19.6921C27.5838 20.5828 27.8286 21.6401 28.7192 22.1244C29.6098 22.6088 30.6671 22.2859 31.2348 21.4786C31.7192 20.6661 31.3963 19.5307 30.5838 19.0463Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M28.7192 36.1443C27.8286 36.6287 27.5838 37.7641 28.0734 38.5766C28.5577 39.4672 29.6932 39.712 30.5838 39.2224C31.4744 38.738 31.7192 37.6026 31.2348 36.7901C30.7452 35.8995 29.6098 35.6599 28.7192 36.1443Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M58.6245 39.2237C59.5152 39.7133 60.5673 39.3851 61.135 38.5778C61.6245 37.6872 61.3798 36.6299 60.4891 36.1456C59.5985 35.6612 58.5412 35.9841 57.9735 36.7914C57.4891 37.6039 57.812 38.7393 58.6245 39.2237Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M60.4896 22.2087C61.3802 21.7191 61.625 20.5837 61.1354 19.7764C60.651 18.8857 59.5156 18.641 58.625 19.1253C57.7344 19.6149 57.4896 20.7503 57.974 21.5576C58.4635 22.3701 59.599 22.693 60.4896 22.2087Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M51.6564 13.4519C51.1668 14.3425 51.4116 15.3998 52.3022 15.8842C53.1928 16.3686 54.2501 16.0456 54.8126 15.2384C55.3022 14.3425 55.0574 13.2904 54.1668 12.8061C53.2762 12.3165 52.1408 12.6446 51.6564 13.4519Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M43.2243 30.0671C43.5524 30.5514 44.0368 30.7962 44.6045 30.7962C44.9274 30.7962 45.2504 30.7129 45.4952 30.5514L50.2764 27.39C51.0056 26.9056 51.2504 25.9316 50.766 25.1243C50.2764 24.3952 49.3024 24.1504 48.4952 24.6348L46.2243 26.1764V17.5046C46.2243 16.6139 45.4952 15.8848 44.6045 15.8848C43.7139 15.8848 42.9847 16.6139 42.9847 17.5046V28.2858C42.9847 28.4473 42.9847 28.6087 43.0629 28.7702C42.9014 29.1764 42.9847 29.6608 43.2243 30.0671Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M65.2712 40.0365C67.0524 36.7917 68.0264 33.0677 68.0264 29.1771C68.0264 16.3698 57.4899 6 44.6045 6C31.7191 6 21.1826 16.3698 21.1826 29.1771C21.1826 33.1458 22.1566 36.7917 23.9378 40.0365H27.7503C25.9639 37.3594 24.8285 34.2031 24.5055 30.7969H26.4535C27.3441 30.7969 28.0732 30.0677 28.0732 29.1771C28.0732 28.2865 27.3441 27.5573 26.4535 27.5573H24.5055C25.318 17.8281 33.1774 10.0521 42.9847 9.32292V11.1875C42.9847 12.0781 43.7139 12.8073 44.6045 12.8073C45.4951 12.8073 46.2243 12.0781 46.2243 11.1875V9.32292C56.0316 10.1302 63.891 17.8281 64.7035 27.5573H62.7555C61.8649 27.5573 61.1357 28.2865 61.1357 29.1771C61.1357 30.0677 61.8649 30.7969 62.7555 30.7969H64.7035C64.4587 34.2031 63.2451 37.3594 61.4587 40.0365H65.2712Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M13 46.5202V52.9993C13 54.7858 14.4583 56.2441 16.2396 56.2441H16.3229L22.6406 105.109C22.9635 107.702 25.2344 109.729 27.8281 109.729H61.3802C64.0521 109.729 66.3229 107.786 66.5677 105.109L72.8854 56.2441H72.9688C74.75 56.2441 76.2083 54.7858 76.2083 52.9993V46.5202C76.2083 44.7337 74.75 43.2754 72.9688 43.2754H16.2396C14.4583 43.2754 13 44.7337 13 46.5202ZM63.3229 104.786C63.2448 105.76 62.3542 106.489 61.2969 106.489H27.8281C26.776 106.489 25.9635 105.76 25.8021 104.708L19.5625 56.2441H69.6458L63.3229 104.786ZM72.9688 52.9993H16.2396V46.5202H72.9688V52.9993Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M32.1251 98.4635C32.2032 99.276 32.9324 99.8437 33.7449 99.8437C33.8282 99.8437 33.9063 99.8437 33.9897 99.8437C34.8803 99.7603 35.5261 98.953 35.3647 98.0624L31.6355 69.2916C31.5574 68.401 30.7449 67.7499 29.8542 67.9114C28.9636 67.9947 28.3126 68.8072 28.4792 69.6978L32.1251 98.4635Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M55.302 99.8434C55.3801 99.8434 55.4634 99.8434 55.5468 99.8434C56.3541 99.8434 57.0832 99.2757 57.1666 98.4632L60.8124 69.7757C60.8905 68.885 60.328 68.0777 59.4322 67.9944C58.5416 67.9111 57.7343 68.4788 57.6509 69.3694L54.0051 98.0621C53.7603 98.9528 54.4114 99.76 55.302 99.8434Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M44.6042 99.8424C45.4948 99.8424 46.224 99.1133 46.224 98.2227V67.7487C46.224 66.8581 45.4948 66.1289 44.6042 66.1289C43.7135 66.1289 42.9844 66.8581 42.9844 67.7487V98.2227C42.9844 99.1133 43.7135 99.8424 44.6042 99.8424Z"
        fill="var(--primary-text-color)"
      />
      <path
        d="M97.1982 29.015L88.042 19.8535C86.7451 18.5566 84.7191 18.5566 83.4222 19.8535L80.9899 22.2858L72.4847 13.7754C71.8337 13.1296 70.8597 13.1296 70.2139 13.7754C69.5628 14.4264 69.5628 15.4004 70.2139 16.0462L101.011 46.8431C101.334 47.166 101.74 47.3275 102.141 47.3275C102.547 47.3275 102.953 47.166 103.276 46.8431C103.927 46.1921 103.927 45.2233 103.276 44.5723L94.766 36.0619L97.1982 33.6348C98.4951 32.3379 98.4951 30.3119 97.1982 29.015ZM92.5785 33.6348L83.4222 24.4733L85.693 22.2077L94.8493 31.3639L92.5785 33.6348Z"
        fill="var(--primary-text-color)"
      />
    </SvgIcon>
  );
}

export default WastingTimeIcon;
