export default class BaseLandingPageService {
  getName() {
    return '';
  }

  getPath() {
    return '/';
  }

  getSeoTitle() {
    return 'Turn Your Data into Actionable Goals with goalskeeper.io';
  }

  getSeoDescription() {
    return 'Set, track and manage your small business goals. Break down the goals into milestones, experiments, and tasks. Integrate with other online tools.';
  }

  getTitleArray() {
    return ['goal-tracking', ' app for your ', 'small business'];
  }

  getDescriptionArray() {
    return [
      'With goalskeeper.io, you can set, track and manage your small business goals. You can break down the goals into milestones, experiments, and tasks. You can focus on the crucial things with daily, weekly, monthly, and quarterly routines. You can also integrate with other online tools that you are already using.'
    ];
  }

  getBlogPosts() {
    return [
      {
        title: 'Why Tracking Your Marketing Data Matters: A Guide for Small Business Owners',
        description:
          'The journey from guessing what is working in your digital marketing to knowing what is working',
        url: 'https://blog.goalskeeper.io/why-tracking-your-marketing-data-matters-a-guide-for-small-business-owners'
      },
      {
        title: 'Why Every Small Business Needs a Marketing Strategy and How to Create One',
        description:
          'Learn why a marketing strategy is crucial for your small business and discover how to create and measure one effectively.',
        url: 'https://blog.goalskeeper.io/why-every-small-business-needs-a-marketing-strategy-and-how-to-create-one'
      }
    ];
  }

  getMainVideo() {
    return 'https://www.youtube.com/embed/1tLsX_LCtnw';
  }
}
