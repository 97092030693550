import classnames from 'classnames';
import { TEXT_VARIANTS } from 'constants/text';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import DoNotEnterIcon from 'style-book/icons/DoNotEnterIcon';
import TextComponent from 'style-book/text/TextComponent';
import PageSection, { SECTION_BACKGROUND_TYPES } from 'widgets/sections/PageSection';

import './FauilreSection.scss';

function FauilreSection() {
  return (
    <PageSection
      className="failure-section"
      contentClassName="failure-content"
      backgroundType={SECTION_BACKGROUND_TYPES.TOP_CURLY_WAVE}>
      <TextComponent variant={TEXT_VARIANTS.SUBTITLE}>
        {"Don't Let Guesswork Hold You Back"}
      </TextComponent>
      <TextComponent variant={TEXT_VARIANTS.BODY}>
        {"If you don't track what's working, you could be:"}
      </TextComponent>
      <div className="failures">
        <div className="failure-bullets">
          <div className="failure-bullet">
            <DoNotEnterIcon size="medium" />
            <TextComponent variant={TEXT_VARIANTS.BODY}>
              Wasting money on ineffective ads
            </TextComponent>
          </div>
          <div className="failure-bullet">
            <DoNotEnterIcon size="medium" />
            <TextComponent variant={TEXT_VARIANTS.BODY}>
              Spending hours trying to make sense of data
            </TextComponent>
          </div>
          <div className="failure-bullet">
            <DoNotEnterIcon size="medium" />
            <TextComponent variant={TEXT_VARIANTS.BODY}>
              Missing out on customers who are ready to buy
            </TextComponent>
          </div>
          <div className="failure-bullet">
            <DoNotEnterIcon size="medium" />
            <TextComponent variant={TEXT_VARIANTS.BODY}>
              Falling behind competitors who know their numbers
            </TextComponent>
          </div>
        </div>
        <StaticImage
          className={classnames('failure-image', 'scale-up-animation')}
          src={'../../../images/failure.webp'}
          placeholder="blurred"
          loading="lazy"
          alt="Frustrated business owner due to not using goalskseeper.io and ignore the business marketing data"
          transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
        />
      </div>
    </PageSection>
  );
}

export default FauilreSection;
