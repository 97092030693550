import React from 'react';
import PageSection from 'widgets/sections/PageSection';

import './VideoSection.scss';

function VideoSection() {
  return (
    <PageSection className="video-section">
      <div className="wave1-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1362 358"
          fill="none"
          preserveAspectRatio="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1361.05 354.334V-271.479H0.661133V315.923C18.1507 304.016 33.6351 291.287 46.3643 277.959C235.89 78.834 573.401 129.23 793.073 234.933C958.328 314.511 1167.2 370.417 1361.05 354.334Z"
            fill="#CBE6EE"
          />
        </svg>
      </div>
      <div className="demo-wrapper">
        <iframe
          style={{ maxWidth: '95%', zIndex: 2, borderRadius: '8px' }}
          width="100%"
          height="100%"
          src={'https://www.youtube.com/embed/FkxfOBYlrp4'}
          title="YouTube video player"
          frameBorder="0"
          loading="lazy"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      </div>
    </PageSection>
  );
}

export default VideoSection;
