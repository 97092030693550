import classnames from 'classnames';
import { TEXT_VARIANTS } from 'constants/text';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import TextComponent from 'style-book/text/TextComponent';
import PageSection, { SECTION_BACKGROUND_TYPES } from 'widgets/sections/PageSection';

import './GuideSection.scss';

function GuideSection() {
  return (
    <PageSection
      className="guide-section"
      contentClassName="guide-content"
      backgroundType={SECTION_BACKGROUND_TYPES.BOTTOM_CURLY_WAVE}>
      <TextComponent className="guide-subtitle" variant={TEXT_VARIANTS.HEADING}>
        {"Marketing Tools Assume You're a Data Expert"}
      </TextComponent>
      <TextComponent className="guide-description" variant={TEXT_VARIANTS.BODY}>
        {`And the worst part? Every "solution" out there assumes you're a data analyst—or can afford
        to hire one.`}
      </TextComponent>
      <TextComponent className="guide-title" variant={TEXT_VARIANTS.SUBTITLE}>
        {"Shouldn't a DIY marketer have a simple way to see what's working?"}
      </TextComponent>
      <StaticImage
        className={classnames('questions-image', 'scale-up-animation')}
        src={'../../../images/questions.webp'}
        placeholder="blurred"
        loading="lazy"
        alt="A sample of how goalskeeper.io simplified marketing analytics, allowing to deep dive into the numbers without being data analysis"
        transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
      />
    </PageSection>
  );
}

export default GuideSection;
