import React from 'react';
import SvgIcon from 'style-book/icons/svg-icon/SvgIcon';

function MagnifyingGlassIcon(props) {
  return (
    <SvgIcon viewBox="0 0 96 96" fill="none" {...props}>
      <path
        d="M85.9028 10.107C79.3829 3.58462 70.7153 0 61.4975 0C52.2748 0 43.6072 3.58462 37.0835 10.107C30.5612 16.6231 26.9703 25.2971 26.9703 34.5198C26.9703 42.5356 29.6945 50.1294 34.6871 56.2649L28.0228 62.928L26.5043 61.4095L6.28782 81.6185L14.3802 89.7096L34.5904 69.5006L33.0782 67.9834L39.7425 61.3191C45.878 66.3179 53.4717 69.0359 61.4887 69.0359C70.7115 69.0359 79.3804 65.445 85.9028 58.9226C99.3657 45.4634 99.3657 23.5663 85.9028 10.107ZM82.1762 55.1898C76.6498 60.7162 69.3073 63.7557 61.4887 63.7557C53.6765 63.7557 46.3339 60.7137 40.8076 55.1898C35.2862 49.6697 32.2442 42.3284 32.2442 34.5161C32.2442 26.7038 35.2862 19.3574 40.8101 13.8361C46.3314 8.31094 53.6727 5.27142 61.4925 5.27142C69.3048 5.27142 76.6473 8.31345 82.1737 13.8361C93.5744 25.243 93.5744 43.7954 82.1762 55.1898Z"
        fill="currentColor"
      />
      <path
        d="M61.4925 9.20395C54.729 9.20395 48.3774 11.8415 43.5946 16.6181C42.8749 17.3378 42.8749 18.5109 43.5946 19.2306C44.3143 19.9503 45.4874 19.9503 46.2083 19.2306C50.2916 15.1473 55.7187 12.9041 61.4925 12.9041C67.2701 12.9041 72.6947 15.1473 76.7817 19.2306C77.1384 19.5936 77.6132 19.7707 78.0905 19.7707C78.5577 19.7707 79.0325 19.5936 79.398 19.2306C80.1176 18.5109 80.1176 17.3378 79.398 16.6181C74.6126 11.8415 68.2548 9.20395 61.4925 9.20395Z"
        fill="currentColor"
      />
      <path
        d="M1.5477 86.3649C-0.5159 88.4222 -0.5159 91.7682 1.5477 93.823L2.17444 94.4561C4.23929 96.5146 7.57898 96.5146 9.64007 94.4561L12.4183 91.6778L4.32596 83.5867L1.5477 86.3649Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default MagnifyingGlassIcon;
